.color-blue {
  color: #033d4f !important;
}
.color-orange {
  color: #f87e2b !important;
}
.color-gray {
  color: #404040 !important;
}
.color-white {
  color: white;
}
.bg-orange {
  background-color: #f87e2b;
}
.bg-gray {
  background-color: #A2A2A2;
}
.font-bold {
  font-weight: 700;
}
.list-style-type-none {
  list-style-type: none;
}
.line-height-1 {
  line-height: 1;
}
.line-height-1-5 {
  line-height: 1.5;
}
.line-height-2 {
  line-height: 2;
}
.font-weight-bold {
  font-weight: 700;
}
.font-weight-light {
  font-weight: 400;
}
.font-size-small {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small {
    font-size: 14px;
}
.font-size-medium {
    font-size: 16px;
}
.font-size-big {
    font-size: 21px;
}
.font-size-large {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small {
    font-size: 14px;
}
.font-size-medium {
    font-size: 14px;
}
.font-size-big {
    font-size: 16px;
}
.font-size-large {
    font-size: 21px;
}
}
.process-structure {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 125px 0 175px 0;
}
.process-structure .auth-process-body {
  width: 95vw;
  max-width: 600px;
}
.process-structure .apply-process-body {
  width: 95vw;
  max-width: 1280px;
  padding: 0 50px;
}
.auth-form-block {
  width: 100%;
  max-width: 600px;
}
.title-tips {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #404040;
  text-align: center;
}
.submit-btn {
  width: 100%;
  color: white;
  background-color: #f87e2b;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
}
.disable-btn {
  width: 100%;
  background-color: #808080;
  color: white;
  padding: 21px 0;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: not-allowed;
}
.v-stepper {
  box-shadow: none !important;
}
.v-stepper__label {
  text-align: center !important;
  line-height: 1.2;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px #f87e2b !important;
  color: #f87e2b !important;
}
.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  text-shadow: 0px 0px 0px #f87e2b;
  color: #f87e2b !important;
}
.stepper {
  margin-bottom: 50px;
}
@media only screen and (max-width: 960px) {
.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
}
}
@media only screen and (max-width: 600px) {
.process-structure {
    padding: 40px 0 100px 0;
}
.v-stepper__header {
    justify-content: center !important;
    box-shadow: none !important;
}
.v-stepper__header .v-stepper__step {
    padding-bottom: 0;
}
.v-stepper__header .v-divider {
    display: none;
}
}
.border-orange-input fieldset {
  border: 1px solid #f87e2b !important;
}
.border-orange-input.v-input--is-focused fieldset {
  border: 2px solid #f87e2b !important;
}
.v-text-field.v-text-field--enclosed {
  margin-bottom: 15px !important;
}
.white-btn {
  border: 1px solid #f87e2b;
  background-color: white;
  color: #f87e2b;
}
.blue-btn {
  color: white;
  background-color: #033d4f;
}
.orange-btn {
  color: white;
  background-color: #f87e2b;
}
.popup {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
}
.popup .mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.popup .panel {
  position: absolute;
  z-index: 501;
  background-color: white;
  width: 70vw;
  box-shadow: 2.56036px 2.56036px 17.0691px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  top: 15vh;
  left: 50vw;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.popup .panel .close-icon {
  position: absolute;
  top: 27px;
  right: 35px;
  color: #f87e2b;
  font-size: 32px;
  cursor: pointer;
}
.popup .panel .checkbox-box {
  display: flex;
  justify-content: center;
}
.popup .panel .actions {
  display: flex;
  justify-content: center;
}
.popup .panel .actions .btn {
  width: 160px;
  height: 53px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.1em;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.popup .panel .actions .btn:not(:last-child) {
  margin-right: 10px;
}
@media screen and (max-width: 600px) {
.popup .panel {
    width: 95vw;
}
.popup .panel .actions .btn {
    width: 140px;
    height: 48px;
    font-size: 14px;
}
}
html {
  scroll-behavior: smooth !important;
}
.panel-item {
  border: 1px solid #f87e2b;
  border-bottom: 0px;
}
.panel-item:last-child {
  border-bottom: 1px solid #f87e2b;
}
.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-overlay {
  z-index: 99999 !important;
}
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.web-content {
  width: 100vw;
  max-width: 1440px;
}
.page-center {
  display: flex;
  justify-content: center;
}
.page-banner {
  margin-bottom: 80px;
}
.page-banner img {
  width: 100vw;
}
.desktop-banner {
  display: block;
}
.mobile-banner {
  display: none;
}
.page-structure {
  display: flex;
  justify-content: space-between;
  padding: 0 85px;
  width: 100%;
  position: relative;
}
.page-structure .page-content {
  max-width: 1010px;
  width: calc(100% - 200px);
}
.normal-page .block {
  width: 100%;
}
.normal-page .block:not(:last-child) {
  margin-bottom: 50px;
}
.normal-page .block-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.normal-page .block-title .dot {
  width: 10px;
  height: 10px;
  margin-right: 17px;
  background-color: #f87e2b;
}
.normal-page .block-sub-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
}
.normal-page .sub-block .sub-title {
  font-weight: 700;
  font-size: 21px;
  letter-spacing: 0.1em;
  color: #033d4f;
  margin-bottom: 30px;
  border-left: 4px solid #033d4f;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.normal-page .sub-block .sub-title .title-tips {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #033d4f;
}
.normal-page .sub-block:not(:last-child) {
  margin-bottom: 30px;
}
.process-page-title-row {
  display: flex;
  justify-content: center;
}
.process-page-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.6em;
  text-indent: 0.6em;
  color: #404040;
  padding-bottom: 12px;
  border-bottom: 3px solid #f87e2b;
  text-align: center;
}
@media screen and (max-width: 1024px) {
.page-structure .page-content {
    width: 100%;
}
}
@media screen and (max-width: 960px) {
.desktop-banner {
    display: none;
}
.page-structure {
    padding: 0 30px;
}
.mobile-banner {
    display: block;
}
.normal-page .block-title {
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 30px;
}
.normal-page .block-title .dot {
    width: 5px;
    height: 5px;
    margin-right: 11px;
}
.normal-page .sub-block .sub-title {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
}
.normal-page .sub-block .sub-title .title-tips {
    font-size: 12px;
}
.normal-page .sub-block:not(:last-child) {
    margin-bottom: 30px;
}
}
.v-input {
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 600px) {
.page-banner {
    margin-bottom: 30px;
}
.page-structure {
    padding: 0 30px;
}
}
@media screen and (max-width: 400px) {
.page-structure {
    padding: 0 15px;
}
}
.color-blue {
  color: #033d4f !important;
}
.color-orange {
  color: #f87e2b !important;
}
.color-gray {
  color: #404040 !important;
}
.color-white {
  color: white;
}
.bg-orange {
  background-color: #f87e2b;
}
.bg-gray {
  background-color: #A2A2A2;
}
.font-bold {
  font-weight: 700;
}
.list-style-type-none {
  list-style-type: none;
}
.line-height-1 {
  line-height: 1;
}
.line-height-1-5 {
  line-height: 1.5;
}
.line-height-2 {
  line-height: 2;
}
.font-weight-bold {
  font-weight: 700;
}
.font-weight-light {
  font-weight: 400;
}
.font-size-small {
  font-size: 16px;
  letter-spacing: 0.1em;
}
.font-size-medium {
  font-size: 21px;
  letter-spacing: 0.1em;
}
.font-size-big {
  font-size: 28px;
  letter-spacing: 0.1em;
}
.font-size-large {
  font-size: 45px;
  letter-spacing: 0.1em;
}
.text-justify {
  text-align: justify;
}
@media screen and (max-width: 960px) {
.font-size-small {
    font-size: 14px;
}
.font-size-medium {
    font-size: 16px;
}
.font-size-big {
    font-size: 21px;
}
.font-size-large {
    font-size: 28px;
}
}
@media screen and (max-width: 600px) {
.font-size-small {
    font-size: 14px;
}
.font-size-medium {
    font-size: 14px;
}
.font-size-big {
    font-size: 16px;
}
.font-size-large {
    font-size: 21px;
}
}
.card-group {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-gap: 40px;
}
.card-group .card-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  text-decoration: none;
  margin-top: 30px;
  margin-right: 40px;
  cursor: pointer;
}
.card-group .card-item .card-content {
  padding: 15px;
  line-height: 2;
  letter-spacing: 0.1em;
}
.card-group .card-item .card-content .card-title {
  font-size: 21px;
  font-weight: 700;
  color: #033d4f;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.card-group .card-item .card-content .card-caption {
  font-size: 16px;
  font-weight: 700;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.card-group .card-item:nth-child(3n+3) {
  margin-right: 0;
}
.card-group .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.card-group .v-sheet.v-card {
  border-radius: 0;
}
.more {
  display: flex;
  justify-content: center;
}
.more .more-text {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.more .more-text span {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  color: #f87e2b;
}
.more .more-text .v-icon {
  color: #f87e2b;
}
.v-image {
  background-color: #d9d9d9;
}
.border {
  height: 140px;
  border: 1px solid #f87e2b;
}
.title-center {
  text-align: center;
}
.title-justify {
  text-align: justify;
}
@media screen and (max-width: 1400px) {
.card-group {
    justify-content: space-evenly;
}
.card-group .card-item {
    margin-right: 0;
    margin-left: 0;
}
}
@media screen and (max-width: 960px) {
.card-group {
    flex-direction: column;
}
}